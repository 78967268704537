export default function useActionPlansChart({ details }) {
  const douPlansByStatus = () => {
    let colors = ['#9BA2AA', '#ff8800', '#e32528', '#00dc94', '#FAB32C', '#227ce2'];

    let chartData = {
      labels: ['Agendado', 'Em análise', 'Concluído', 'Atrasado', 'Invalidado', 'Cancelado'],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0]
        }
      ]
    };

    let labels = [];
    let values = [];
    let backgroundColor = [];
    let hoverBackgroundColor = [];

    const { status, total } = details;

    if (total > 0) {
      for (let stat in status) {
        labels.push(stat);
        values.push(parseFloat(((status[stat] / total) * 100).toFixed(2)));

        let color = colors.pop();
        backgroundColor.push(color);
        hoverBackgroundColor.push(color);
      }

      chartData.labels = labels;
      chartData.datasets[0].data = values;
      chartData.datasets[0].backgroundColor = backgroundColor;
      chartData.datasets[0].hoverBackgroundColor = hoverBackgroundColor;
    }

    return chartData;
  };

  const chartOptions = {
    cutoutPercentage: 40,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          padding: 24,
          borderRadius: 1000,
          useBorderRadius: true,
          usePointStyle: true
        }
      }
    },
    responsive: false,
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (tooltip, data) => {
          const { datasets, labels } = data;
          const { index, datasetIndex } = tooltip;
          return `${labels[index]}: ${datasets[0].data[index]}%`;
        }
      }
    }
  };

  return { douPlansByStatus, chartOptions };
}
