import Axios from 'axios';
import baseURL from '../variables/api';

const authToken = localStorage.getItem('banana');

const List = async (token, storeCode) => {
  return Axios.get(`${baseURL}/peb/bandag-cards/${storeCode}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const ListSubmissions = async () => {
  return Axios.get(`${baseURL}/bandag-rules/submissions`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    }
  });
};

const Done = async (token, idRulesAndLicenses, storeCode, formData) => {
  return Axios.put(
    `${baseURL}/peb/bandag-cards/${idRulesAndLicenses}/done/${storeCode}`,
    formData,
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

const Validate = async (idRulesAndLicenses) => {
  return Axios.put(
    `${baseURL}/bandag-rules/submissions/${idRulesAndLicenses}/validate`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + authToken
      }
    }
  );
};

const Invalidate = async (idRulesAndLicenses, reason) => {
  return Axios.put(
    `${baseURL}/bandag-rules/submissions/${idRulesAndLicenses}/invalidate/${reason}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + authToken
      }
    }
  );
};

const RulesAndLicensesService = {
  List,
  ListSubmissions,
  Done,
  Validate,
  Invalidate
};

export default RulesAndLicensesService;
