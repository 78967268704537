/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/argon-dashboard-react.scss';

import AdminLayout from './layouts/Admin.jsx';
import AuthLayout from './layouts/Auth.jsx';
import SacPersonal from './views/excelencia/Sac/SacPersonal';

import UserProvider from './components/Contexts/UserContext';
import StoreProvider from './components/Contexts/StoreContext';

document.title = `Programa de Excelência ${new Date().getFullYear()}`;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <UserProvider>
    <StoreProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/sac-personal/:store" component={SacPersonal} />
          <Redirect from="/" to="/auth/login" />
        </Switch>
      </BrowserRouter>
    </StoreProvider>
  </UserProvider>,
  document.getElementById('root')
);
