import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import AuthFooter from '../components/Footers/AuthFooter.jsx';

import routes from '../routes.js';

class Auth extends React.Component {
  getRoutes = (routes) => {
    return routes
      .filter((prop) => prop.layout === '/auth')
      .map((prop, key) => (
        <Route path={prop.layout + prop.path} component={prop.component} key={key} />
      ));
  };

  render() {
    return (
      <>
        <div className="main-content">
          <div className="header header-gradient py-8 py-lg-8">
            <div
              className="separator separator-bottom separator-skew zindex-100"
              style={{ bottom: '-1px' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon fill="#f8f9fe" points="2560 0 2560 100 0 100" />
              </svg>
            </div>
          </div>

          <Container className="mt--9 pb-5">
            <Row className="justify-content-center">
              <Switch>{this.getRoutes(routes)}</Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Auth;
