import Axios from 'axios';
import BaseUrl from '../variables/api';

const List = async (token, storeCode, category) => {
  return Axios.get(`${BaseUrl}/peb/emblems/${storeCode}/${category}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const EmblemsService = {
  List
};

export default EmblemsService;
