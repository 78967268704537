import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  Grid,
  Divider,
  Checkbox,
  FormControl,
  FormControlLabel
} from '@mui/material';

const DialogAdvisorAlert = ({ isOpen, setClosedDialog = () => {} }) => {
  const [showAgain, setShowAgain] = useState(false);

  const handleShowAgain = (event) => {
    setShowAgain(event.target.checked);
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth={false} onClose={() => setClosedDialog(false)}>
      <Box>
        <DialogTitle className="dialogtitle-default" sx={{ paddingTop: 4 }}>
          <Typography variant="subtitle1" className="fontweight-600 textalign-center">
            Olá, CTC Pirelli!
          </Typography>
        </DialogTitle>

        <DialogContent className="dialogcontent-default">
          <Divider />
        </DialogContent>

        <DialogContent className="dialogcontent-default">
          <Typography variant="body1">
            Bem-vindo(a) ao questionário do Programa de Excelência Pirelli Performance Center! Antes de iniciar o
            preenchimento, atente-se para as seguintes instruções:
          </Typography>

          <ol className="mt-3">
            <li className="mb-2">
              Observe atentamente os enunciados, instruções e imagens de exemplo de cada questão
              para um correto preenchimento.
            </li>
            <li className="mb-2">
              A maioria das questões precisam de fotos/arquivos comprobatórios. Dê preferência para
              fotos NA HORIZONTAL, principalmente para as fachadas.
            </li>
            <li className="mb-2">
              As fotos devem ilustrar o assunto da questão de FORMA CLARA. Às vezes apenas uma foto
              não é suficiente. Tire quantas achar necessário para comprovar um item (Exemplo: para
              ilustrar as condições da pintura interna, tire uma foto ampla que pegue a maioria das
              paredes e uma foto mais aproximada para pegar eventuais problemas).
            </li>
            <li className="mb-2">
              Fotos que não comprovarem determinada questão podem ser recusadas na auditoria da
              direção do Programa (sujeito à penalização dependendo do caso).
            </li>
          </ol>

          <Typography variant="body1">Bom trabalho!</Typography>

          <FormControl className="mt-3">
            <FormControlLabel
              control={<Checkbox checked={showAgain} onChange={handleShowAgain} />}
              label="Não mostrar essa mensagem novamente"
            />
          </FormControl>
        </DialogContent>

        <DialogActions
          className="dialogactions-default"
          sx={{
            paddingX: 2,
            paddingBottom: 4,
            justifyContent: 'right'
          }}
        >
          <Button
            type="button"
            className="background-primary color-white borderradius-default"
            size="small"
            onClick={() => setClosedDialog(showAgain)}
          >
            Começar questionário
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DialogAdvisorAlert;
