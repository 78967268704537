import './style.scss';

import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { EmblemImages } from '../../data/Images';

const EmblemCard = ({ title, image, active, description }) => {
  const emblemImage = EmblemImages[active ? image : 'inativo'];

  const infoTooltip = (
    <>
      <Typography
        variant="subtitle2"
        className="background-primary-2 color-white infoemblem-title"
        padding={1}
      >
        COMO GANHAR?
      </Typography>

      <Typography
        variant="subtitle2"
        className="color-white fontsize-12"
        paddingX={2.5}
        paddingY={1.5}
      >
        {description}
      </Typography>
    </>
  );

  return (
    <Box className={`emblemcard-box ${active ? 'active' : ''}`} padding={2} paddingY={3}>
      <Tooltip
        title={infoTooltip}
        arrow
        componentsProps={{
          tooltip: {
            className: 'infoemblem-tooltip background-primary'
          },
          arrow: {
            className: 'color-primary-2'
          }
        }}
      >
        <IconButton className="iconbutton-info background-primary color-white" size="small">
          <InfoOutlined fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <img
        src={emblemImage}
        alt={`${title.toLowerCase().replace(/ /g, '')}`}
        className="emblemcard-badge"
      />

      <Typography variant="body2" className="fontsize-10 fontweight-bold" marginTop={3}>
        {title}
      </Typography>
    </Box>
  );
};

export default EmblemCard;
