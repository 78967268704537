import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
// core components
import axios from 'axios';

import routes from '../routes.js';
import baseURL from '../variables/api';

import Sidebar from '../components/Sidebar/Sidebar.jsx';

import { UserContext } from '../components/Contexts/UserContext';
import { StoreContext } from '../components/Contexts/StoreContext';
import StoreContainer from '../components/StoreContainer/StoreContainer';
import EventsService from '../services/EventsService';
import logo from '../assets/img/brand/excelencia-logo.png';

function Admin(props) {
  const { setUser, setToken } = useContext(UserContext);
  const {
    store,
    storeCode,
    selectedCategory,
    setStore,
    setConsolidatedRegions,
    setConsolidatedRegion,
    setStoreContainerOpened,
    loadCategories
  } = useContext(StoreContext);

  const [userLogged, setUserLogged] = useState(false);
  const [mainContent, setMainContent] = useState();

  const [userRoutes, setUserRoutes] = useState([]);

  // const url = window.location.href;
  useEffect(() => {
    const validSelectedCategory =
      selectedCategory !== undefined && selectedCategory.trim().length > 0;

    if (validSelectedCategory) {
      saveEvent();
    }

    if (store && store.pending === 'Y') {
      const { history } = props;
      history.push('/admin/subscription');
    }
  }, [storeCode, selectedCategory, window.location.pathname]);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    props.location.search = '';

    let auth = query.get('auth');

    if (auth != null) {
      localStorage.setItem('banana', auth);
    } else if (localStorage.getItem('banana') == null) {
      const { history } = props;
      history.push('/auth/login');
    }

    (async () => {
      if (userLogged == false) {
        await getUser();
      }
    })();
  }, [false]);

  const saveEvent = () => {
    const pageUrl = window.location.pathname;
    const pageDescription = routes.find((r) => `${r.layout}${r.path}` == pageUrl).name || '';

    EventsService.Save(2, storeCode, selectedCategory, pageDescription, pageUrl).catch((err) => {
      console.error(err);
    });
  };

  async function getUser() {
    try {
      const [userResponse, consolidatedFiltersResponse] = await Promise.all([
        axios.get(baseURL + '/my-user', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        }),
        axios.get(baseURL + '/peb/consolidated-filters', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('banana')
          }
        })
      ]);

      let user = userResponse.data.user;
      localStorage.setItem('user', JSON.stringify(user));

      let filteredRoutes = routes.filter(
        (route) =>
          route.hidden !== true &&
          (!route.isAdmin || (route.isAdmin && user.admin.toUpperCase() === 'Y')) &&
          route.profilesAllowed &&
          route.profilesAllowed.indexOf(user.profileId) > -1
      );

      setUserRoutes(filteredRoutes);

      if (user.clients === null || user.clients.length === 0) {
        console.error('Sem lojas inscritas para este usuário');
      }

      if (consolidatedFiltersResponse.data.length > 0) {
        setConsolidatedRegions(consolidatedFiltersResponse.data);
        setConsolidatedRegion(consolidatedFiltersResponse.data[0]);
      } else {
        setConsolidatedRegions([]);
        setStore(user.clients[0]);
      }

      const { history } = props;

      const route = routes.find((route) => {
        let profileAllowed = route.profilesAllowed.includes(user.profileId);
        if (profileAllowed && user.isAdmin === 'Y') return true;
        if (profileAllowed && !route.isAdmin) return true;
        return false;
      });

      const pathname = route ? route.layout + route.path : '/admin/dashboard';

      history.push(pathname);

      setToken(localStorage.getItem('banana'));
      setUserLogged(true);
      setUser(user);
      loadCategories(user);
    } catch (err) {
      console.error(err);
      const { history } = props;
      history.push('/auth/login');
    }
  }

  function getRoutes(routes) {
    return routes
      .filter((prop) => prop.layout === '/admin')
      .map((prop, key) => (
        <Route path={prop.layout + prop.path} component={prop.component} key={key} />
      ));
  }

  const setContentRef = (element) => {
    setMainContent(element);
  };

  return (
    <>
      {userLogged && (
        <>
          {(!store || (store && store.pending === 'N')) && (
            <Sidebar
              {...props}
              routes={userRoutes}
              logo={{
                innerLink: '/admin/dashboard',
                imgSrc: logo,
                imgAlt: '...'
              }}
              setShowStoreContainer={() => setStoreContainerOpened(true)}
            />
          )}

          <div className="main-content mt-4" ref={setContentRef}>
            <Switch>{getRoutes(userRoutes)}</Switch>
            <div className="mt-4 mb-6"></div>
          </div>

          <StoreContainer />
        </>
      )}
    </>
  );
}

export default Admin;
