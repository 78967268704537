const MaskTemplate = (value, mask, erasing) => {
  if (erasing) {
    return value;
  }

  let er = /[^0-9/ (),.-]/;
  er.lastIndex = 0;

  if (er.test(value)) {
    return value.substring(0, value.length - 1);
  }

  let isMask;
  let exp = /\-|\.|\/|\(|\)| /g;
  let onlyNumbers = value.toString().replace(exp, '');
  let fieldPosition = 0;
  let newValueField = '';
  let maskLength = onlyNumbers.length;

  for (let i = 0; i <= maskLength; i++) {
    isMask = mask.charAt(i) == '-' || mask.charAt(i) == '.' || mask.charAt(i) == '/';
    isMask = isMask || mask.charAt(i) == '(' || mask.charAt(i) == ')' || mask.charAt(i) == ' ';

    if (isMask) {
      newValueField += mask.charAt(i);
      maskLength++;
    } else {
      newValueField += onlyNumbers.charAt(fieldPosition);
      fieldPosition++;
    }
  }

  value = newValueField;

  if (value.length > mask.length) {
    return value.substring(0, value.length - 1);
  }

  return value;
};

const Cep = (cep, erasing) => {
  return MaskTemplate(cep, '00000-000', erasing);
};

const Cnpj = (cnpj, erasing) => {
  return MaskTemplate(cnpj, '00.000.000/0000-00', erasing);
};

const Phone = (phone, erasing) => {
  return MaskTemplate(phone, '(00) 000000000', erasing);
};

const IntegerNumber = (number) => {
  return MaskTemplate(number, '000000000000000000000000000000', false);
};

const Money = (value) => {
  value = value.replace(/\D/g, '');
  value = (value / 100).toFixed(2) + '';
  value = value.replace('.', ',');
  value = value.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  value = value.replace(/(\d)(\d{3}),/g, '$1.$2,');

  return value;
};

const MaskHelper = {
  Cep,
  Cnpj,
  Phone,
  IntegerNumber,
  Money
};

export default MaskHelper;
