import { useContext } from 'react';

import { colors } from '../variables/charts';

import { barStoreCompare } from '../variables/charts.jsx';

export default function useStoreEvaluation() {
  const regions = {
    Regional: 1,
    Nacional: 2,
    Sudeste: 2,
    'São Paulo': 2,
    'Centro-Oeste': 2,
    Norte: 2,
    Nordeste: 2,
    Sul: 2
  };

  const chartStoreCompare = ({ activeNav, evalRegional, evalStore, evalComparative, region }) => {
    let data = JSON.parse(JSON.stringify(barStoreCompare));

    if (activeNav === 1) {
      if (Object.keys(evalRegional).length > 0) {
        data.labels = [];

        Object.keys(evalRegional).forEach((area, index) => {
          data.labels.push(area);

          data.datasets[0].data[index] = evalStore[area] ? evalStore[area].percent : 0;

          data.datasets[1].data[index] = evalRegional[area].percent;
        });
      }
    } else {
      data.datasets[1].label = `Média ${region}`;

      if (Object.keys(evalComparative[region]).length > 0) {
        data.labels = [];

        Object.keys(evalComparative[region]).forEach((area, index) => {
          data.labels.push(area);

          data.datasets[0].data[index] = evalStore[area] ? evalStore[area].percent : 0;

          data.datasets[1].data[index] = evalComparative[region][area].percent;
        });
      }
    }

    data.datasets.forEach((dataset, i) => {
      if (i === 0) {
        dataset.data.forEach((val, j) => {
          if (val < 50) {
            dataset.backgroundColor[j] = colors.red.light;
            dataset.hoverBackgroundColor[j] = colors.red.hover;
          }
          if (val > 70) {
            dataset.backgroundColor[j] = colors.green.light;
            dataset.hoverBackgroundColor[j] = colors.green.hover;
          }
          if (val >= 50 && val <= 70) {
            dataset.backgroundColor[j] = colors.orange.light;
            dataset.hoverBackgroundColor[j] = colors.orange.hover;
          }
        });
      }

      if (i === 1) {
        dataset.data.map((val, j) => {
          if (val < 50) {
            dataset.backgroundColor[j] = colors.red.dark;
            dataset.hoverBackgroundColor[j] = colors.red.hover;
          }
          if (val > 70) {
            dataset.backgroundColor[j] = colors.green.dark;
            dataset.hoverBackgroundColor[j] = colors.green.hover;
          }
          if (val >= 50 && val <= 70) {
            dataset.backgroundColor[j] = colors.orange.dark;
            dataset.hoverBackgroundColor[j] = colors.orange.hover;
          }
        });
      }
    });

    return data;
  };

  return { regions, chartStoreCompare };
}
