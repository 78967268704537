import './style.scss';

import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from '@mui/material';

const DefaultSelectField = ({
  label,
  name,
  data,
  customData = false,
  color = 'primary',
  margin = 'normal',
  disabled = false,
  helperText = undefined,
  required = true,
  variant = 'outlined',
  value = '',
  multiple = false,
  className = '',
  onChange
}) => {
  const constructSelectOptions = () => {
    if (customData) {
      return data;
    }

    return data.map((item, index) => {
      return (
        <MenuItem value={item.value} key={index}>
          {item.text}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl
      variant={variant}
      fullWidth={true}
      color={color}
      margin={margin}
      className={`selectfield-default ${className}`}
    >
      <InputLabel>{label}</InputLabel>

      <Select
        label={label}
        name={name}
        value={value}
        disabled={disabled}
        required={required}
        multiple={multiple}
        onChange={onChange}
      >
        {constructSelectOptions()}
      </Select>

      {helperText && <FormHelperText> {helperText} </FormHelperText>}
    </FormControl>
  );
};

export default DefaultSelectField;
