import Axios from 'axios';
import BaseUrl from '../variables/api';

const GetSac = async (token, storeCode, category, region, compare) => {
  return Axios.get(`${BaseUrl}/peb/surveys-average`, {
    headers: {
      Authorization: 'Bearer ' + token
    },
    params: {
      storeCode: region || !storeCode ? undefined : storeCode,
      category,
      region,
      compare
    }
  });
};

const GetAreas = async (token, storeCode, category, region, compare) => {
  return Axios.get(`${BaseUrl}/peb/peb-area-evaluation`, {
    headers: {
      Authorization: 'Bearer ' + token
    },
    params: {
      storeCode: region || !storeCode ? undefined : storeCode,
      category,
      region,
      compare
    }
  });
};

const GetTasks = async (token, storeCode, category, region, compare) => {
  return Axios.get(`${BaseUrl}/peb/tasks-status`, {
    headers: {
      Authorization: 'Bearer ' + token
    },
    params: {
      storeCode: region || !storeCode ? undefined : storeCode,
      category,
      region,
      compare
    }
  });
};

const GetACBBudget = async (token, storeCode, category, region, compare) => {
  return Axios.get(`${BaseUrl}/peb/acb-budget`, {
    headers: {
      Authorization: 'Bearer ' + token
    },
    params: {
      storeCode: region || !storeCode ? undefined : storeCode,
      category,
      region,
      compare
    }
  });
};

const GetConsolidatedFilters = async (token) => {
  return Axios.get(`${BaseUrl}/peb/consolidated-filters`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const GetCompareFilters = async (token) => {
  return Axios.get(`${BaseUrl}/peb/compare-filters`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const DashboardService = {
  GetSac,
  GetAreas,
  GetTasks,
  GetACBBudget,
  GetConsolidatedFilters,
  GetCompareFilters
};

export default DashboardService;
