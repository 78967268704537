import './style.scss';

import React from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

const RulesAndLicensesCard = ({
  color,
  category = '',
  title = '',
  buttonText = '',
  buttonColor = '',
  buttonDisabled = true,
  buttonHandleClick = () => {},
  className = '',
  tooltipInvalid = undefined
}) => {
  const backgroundColorElements = `background-${color}`;
  const backgroundButtonColor = `background-${buttonColor}`;

  return (
    <Box className={`rulesandlicensescard-box ${className}`} paddingBottom={0.5}>
      {tooltipInvalid && (
        <Tooltip
          title={tooltipInvalid}
          arrow={!buttonDisabled}
          componentsProps={{
            tooltip: {
              className: `inforulesandlicenses-tooltip background-red`
            },
            arrow: {
              className: 'color-red-2'
            }
          }}
        >
          <HelpOutline fontSize="inherit" className="icon-invalid background-red color-white" />
        </Tooltip>
      )}

      <Typography
        variant="subtitle1"
        padding={1.5}
        paddingX={0.5}
        className={`fontsize-11 rulesandlicensescard-title fontweight-600 color-white ${backgroundColorElements}`}
      >
        {category.toUpperCase()}
      </Typography>

      <Box padding={title.length > 0 ? 4 : 1.5} className="rulesandlicensescard-informations">
        <Typography variant="caption" component="h6" className="fontsize-10">
          {title}
        </Typography>
      </Box>

      <Box padding={1.5} paddingTop={0}>
        <Button
          fullWidth
          className={`fontsize-11 borderradius-default color-white ${backgroundButtonColor}`}
          sx={{
            padding: '10px 0px'
          }}
          disabled={buttonDisabled}
          onClick={buttonHandleClick}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default RulesAndLicensesCard;
