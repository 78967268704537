import Axios from 'axios';
import baseURL from '../variables/api';

const authToken = localStorage.getItem('banana');

const List = async (selectedCategory = undefined, managerId = undefined) => {
  const params = {};

  if (selectedCategory) {
    params.category = selectedCategory;
  }

  if (managerId) {
    params.manager = managerId;
  }

  return await Axios.get(`${baseURL}/peb/advisors`, {
    headers: {
      Authorization: 'Bearer ' + authToken
    },
    params: params
  });
};

const AdvisorService = {
  List
};

export default AdvisorService;
