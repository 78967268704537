import './metrics.scss';

import React from 'react';
import { Grid, Typography } from '@mui/material';

const defaultMetrics = [
  {
    title: 'Aproveitamento'
  },
  {
    title: 'Melhor área'
  },
  {
    title: 'Área de atenção'
  },
  {
    title: 'Melhorias agendadas'
  },
  {
    title: 'Melhorias atrasadas'
  }
];

const Metrics = ({ data }) => {
  const array = data && data.length > 0 ? data : defaultMetrics;

  return (
    <>
      <Grid container className="metrics-container shadow">
        {array.map((metric, index) => {
          return (
            <Grid item className="metric-grid" key={index}>
              <Typography variant="caption" className="color-white">
                {metric.title || '-'}
              </Typography>

              <Typography variant="h5" className="color-white metric-value" marginY={1}>
                {metric.value || '-'}
              </Typography>

              <Typography variant="caption" className="color-white">
                {metric.description || ''}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default Metrics;
