import './style.scss';

import React from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { HelpOutline } from '@mui/icons-material';

const ExtraScoreCard = ({
  color,
  category = '',
  title = '',
  points = '',
  buttonText = '',
  buttonColor = '',
  buttonDisabled = true,
  buttonHandleClick = () => {},
  validity = '',
  className = '',
  tooltipInvalid = undefined
}) => {
  const backgroundColorElements = `background-${color}`;
  const backgroundButtonColor = `background-${buttonColor}`;
  const colorElements = `color-${color}`;

  const validityDate = validity ? validity.split('-') : [];
  const validityDay = validityDate.length > 0 ? validityDate[2] : '';
  const validityMonth = validityDate.length > 0 ? validityDate[1] : '';
  const validityYear = validityDate.length > 0 ? validityDate[0] : '';

  return (
    <Box className={`extrascorecard-box ${className}`} paddingBottom={0.5}>
      {tooltipInvalid && (
        <Tooltip
          title={tooltipInvalid}
          arrow={!buttonDisabled}
          componentsProps={{
            tooltip: {
              className: `infoextrascore-tooltip background-primary`
            },
            arrow: {
              className: 'color-primary-2'
            }
          }}
        >
          <HelpOutline fontSize="inherit" className="icon-invalid background-red color-white" />
        </Tooltip>
      )}

      <Typography
        variant="subtitle1"
        padding={1.5}
        paddingX={0.5}
        className={`fontsize-11 extrascorecard-title fontweight-600 color-white ${backgroundColorElements}`}
      >
        {category.toUpperCase()}
      </Typography>

      <Box padding={1.5} paddingTop={1.2} className="extrascorecard-informations">
        <Typography variant="caption" component="h6" className="fontsize-10">
          {title}
        </Typography>

        {points.length > 0 && (
          <Box paddingX={1.5} paddingTop={1.2}>
            <Typography variant="h4" className={`fontweight-600 ${colorElements}`} lineHeight={0.9}>
              {points}
            </Typography>

            <Typography variant="caption" className={`fontsize-10 fontweight-600 ${colorElements}`}>
              Pontos
            </Typography>
          </Box>
        )}
      </Box>

      <Box padding={1.5} paddingTop={0}>
        <Button
          fullWidth
          className={`fontsize-11 borderradius-default color-white ${backgroundButtonColor}`}
          sx={{
            padding: '10px 0px'
          }}
          disabled={buttonDisabled}
          onClick={buttonHandleClick}
        >
          {buttonText}
        </Button>

        {validity.length > 0 && (
          <Box paddingTop={1.5}>
            <Typography variant="caption" component="h6" className="fontsize-9">
              {`Expira em ${validityDay}/${validityMonth}/${validityYear}`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ExtraScoreCard;
