import './style.scss';

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Avatar, Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { StoreContext } from '../Contexts/StoreContext';

import { KeyboardArrowDown, Menu as MenuIcon } from '@mui/icons-material';
import { UserContext } from '../Contexts/UserContext';
import MaskHelper from '../../helpers/MaskHelper';
import Loader from '../Bridgestone/Loader';
import ErrorAlert from '../UI/ErrorAlert';
import RankingService from '../../services/RankingService';
import FormHelper from '../../helpers/FormHelper';
import AvatarService from '../../services/AvatarService';
import StoreList from '../StoreList';

const StoreContainer = () => {
  const {
    store,
    setStore,
    storeCode,
    storeName,
    storeAvatar,
    selectedCategory,
    consolidatedRegion,
    storeContainerOpened,
    setStoreContainerOpened
  } = useContext(StoreContext);
  const { token, user, setUser } = useContext(UserContext);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loading, setLoading] = useState(false);

  const [storeMenuActive, setStoreMenuActive] = useState(false);
  const uploadInputRef = useRef(null);

  const [anchorElStoreList, setAnchorElStoreList] = useState(null);
  const [storeListLength, setStoreListLength] = useState(0);
  const [ranking, setRanking] = useState(undefined);

  useEffect(() => {
    if (!store || !storeCode || !selectedCategory) {
      return;
    }

    RankingService.Get(token, storeCode, selectedCategory).then((resp) => {
      setRanking((resp.data.ranking[0] && resp.data.ranking[0].position) || 0);
    });
  }, [user, store, storeCode, selectedCategory]);

  const renderRankingText = () => {
    if (ranking) {
      if (ranking.current > ranking.previous) {
        return (
          <>
            <Typography variant="subtitle2" className="color-white fontweight-600">
              Ops!
            </Typography>
            <Typography variant="caption" className="color-white">
              Você caiu {ranking.current - ranking.previous} posições.
            </Typography>
          </>
        );
      } else if (ranking.current < ranking.previous) {
        return (
          <>
            <Typography variant="subtitle2" className="color-white fontweight-600">
              Parabéns!
            </Typography>
            <Typography variant="caption" className="color-white">
              Você subiu {ranking.previous - ranking.current} posições.
            </Typography>
          </>
        );
      }
    }

    return (
      <>
        <Typography variant="subtitle2" className="color-white fontweight-600">
          Vamos lá!
        </Typography>
        <Typography variant="caption" className="color-white">
          Rumo à excelência.
        </Typography>
      </>
    );
  };

  const handleChangeAvatar = (e) => {
    const file = e.target.files[0];

    let formData = FormHelper.GenerateFormData(null, null);
    formData = FormHelper.IncludeKeyValue(formData, 'avatar', file);

    setLoading(true);

    AvatarService.Change(token, storeCode, selectedCategory, formData)
      .then((resp) => {
        const storeAux = resp.data.user.clients.find((client) => client.code == storeCode);
        setStore(storeAux);
        setUser(resp.data.user);
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getStoreInitialLetters = (name, consolidatedRegion) => {
    if (consolidatedRegion && consolidatedRegion.name) {
      return consolidatedRegion.name
        .split(' ')
        .reduce((acc, curr, index) => {
          return index < 2 ? acc + curr[0] : acc;
        }, '')
        .toUpperCase();
    }

    return name.split(' ').reduce((acc, curr, index) => {
      return index < 2 ? acc + curr[0] : acc;
    }, '');
  };

  return (
    <>
      {loading && <Loader />}
      {error && <ErrorAlert text={errorText} />}

      <Box
        className={`store-container-background ${storeContainerOpened ? 'actived' : ''}`}
        onClick={() => setStoreContainerOpened(false)}
      />

      <Box className={`store-container ${storeContainerOpened ? 'actived' : ''}`}>
        <Grid container spacing={4}>
          <Grid item xs={12} display="flex">
            <IconButton
              className="color-white mr-2"
              onClick={() => setStoreMenuActive(!storeMenuActive)}
            >
              <MenuIcon fontSize="small" />
            </IconButton>

            <Avatar src={storeAvatar} alt={storeName} className="default-avatar">
              {getStoreInitialLetters(storeName, consolidatedRegion)}
            </Avatar>
          </Grid>

          <Grid item xs={12} height={storeMenuActive ? 'auto' : '0px'}>
            <Grid container className={`store-menu ${storeMenuActive ? 'actived' : ''}`}>
              <Grid item className="store-data">
                <Typography variant="subtitle2" className="fontweight-600 color-red-2">
                  {storeName || '-'}
                </Typography>

                <Typography variant="caption" className="fontsize-12 color-red-2">
                  {storeCode || '-'}
                </Typography>

                <Divider sx={{ marginY: 1 }} />

                <Box>
                  <Typography variant="caption" className="fontsize-8 fontweight-600 color-grey-4">
                    E-MAIL DE LOGIN
                  </Typography>

                  <Typography
                    variant="body2"
                    className="fontsize-11 color-black"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {user.email || '-'}
                  </Typography>
                </Box>

                <Box marginTop={1}>
                  <Typography variant="caption" className="fontsize-8 fontweight-600 color-grey-4">
                    TELEFONE
                  </Typography>

                  <Typography
                    variant="body2"
                    className="fontsize-11 color-black"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {user.info && user.info.phone ? MaskHelper.Phone(user.info.phone, false) : '-'}
                  </Typography>
                </Box>
              </Grid>

              <Grid item className="store-picture">
                <input
                  ref={uploadInputRef}
                  accept="image/*"
                  type="file"
                  style={{
                    display: 'none'
                  }}
                  onChange={handleChangeAvatar}
                />

                <Avatar src={storeAvatar} alt={storeName} className="default-avatar store-avatar">
                  {getStoreInitialLetters(storeName, consolidatedRegion)}
                </Avatar>

                {user.profile.id == 6 && (
                  <Typography
                    variant="caption"
                    className="fontsize-10 cursor-pointer"
                    onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                  >
                    Trocar foto
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" className="color-white">
              {consolidatedRegion && consolidatedRegion.id
                ? consolidatedRegion.name
                : storeName || '-'}

              {storeListLength > 0 && (
                <IconButton
                  className="background-white color-primary ml-4"
                  size="small"
                  onClick={(e) => setAnchorElStoreList(e.currentTarget)}
                >
                  <KeyboardArrowDown fontSize="small" />
                </IconButton>
              )}
            </Typography>

            <Typography variant="caption" className="color-white">
              {storeCode || '-'}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6} className="container-ranking first background-white">
                <Typography variant="caption" fontSize={10} className="color-primary">
                  Posição no ranking
                </Typography>
                <Typography variant="h3" className="color-primary fontweight-600">
                  {ranking && ranking.current ? ranking.current : 0}º
                </Typography>
              </Grid>

              <Grid item xs={6} className="container-ranking last">
                {renderRankingText()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <StoreList
        anchorEl={anchorElStoreList}
        handleClose={() => setAnchorElStoreList(null)}
        setStoreListLength={(length) => setStoreListLength(length)}
      />
    </>
  );
};

export default StoreContainer;
