import './style.scss';

import React from 'react';
import { Button } from '@mui/material';

const DefaultButton = ({
  label,
  type = 'button',
  variant = 'contained',
  disabled = false,
  disableElevation = true,
  fullWidth = true,
  size = 'large',
  color = 'inherit',
  className = '',
  style = {},
  onClick = () => {},
  sx = {}
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      disabled={disabled}
      disableElevation={disableElevation}
      fullWidth={fullWidth}
      size={size}
      color={color}
      className={`button-default ${className}`}
      onClick={onClick}
      style={{ ...style }}
      sx={sx}
    >
      {label}
    </Button>
  );
};

export default DefaultButton;
