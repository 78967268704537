import { Box, Divider, Menu, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import StringHelper from '../../helpers/StringHelper';
import AdvisorService from '../../services/AdvisorService';
import { StoreContext } from '../Contexts/StoreContext';
import { UserContext } from '../Contexts/UserContext';
import DefaultTextField from '../TextField/TextField';

const AdvisorList = ({ anchorEl, handleClose, setAdvisor }) => {
  const {
    storeCode,
    selectedCategory,
    consolidatedRegions,
    setConsolidatedRegion,
    consolidatedRegion
  } = useContext(StoreContext);
  const { user } = useContext(UserContext);

  const [filterListValue, setFilterListValue] = useState('');
  const [advisorList, setAdvisorList] = useState([]);
  const [consolidatedRegionsList, setConsolidatedRegionsList] = useState([]);

  useEffect(() => {
    setFilterListValue('');
  }, [anchorEl]);

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }

    const managerId = user.profileId == 3 ? user.id : undefined;
    AdvisorService.List(selectedCategory, managerId).then((resp) => {
      setAdvisorList(resp.data);
    });
  }, [user, selectedCategory]);

  useEffect(() => {
    if (consolidatedRegion) {
      setConsolidatedRegionsList(consolidatedRegions.filter((c) => c.id !== consolidatedRegion.id));
    }
  }, [consolidatedRegion]);

  const handleChangeAdvisorOrConsolidatedRegion = (region, advisor) => {
    setConsolidatedRegion(region);
    setAdvisor(advisor);
    handleClose();
  };

  const handleListFiltering = (list, columns = []) => {
    if (filterListValue.trim().length > 0) {
      return list.filter((row) => {
        return columns.some((column) => {
          const columnValue = StringHelper.Normalize(row[column].toString());
          const filterValue = StringHelper.Normalize(filterListValue);

          return columnValue.indexOf(filterValue) > -1;
        });
      });
    }

    return list;
  };

  const consolidatedRegionsListFiltered = handleListFiltering(consolidatedRegionsList, ['name']);
  const advisorListFiltered = handleListFiltering(advisorList, ['name', 'id']);

  if (advisorList.length == 0 && consolidatedRegionsList.length == 0) {
    return <> </>;
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
        className: 'mt-2',
        sx: {
          paddingY: 1,
          borderRadius: 4,
          width: 500,
          maxHeight: '80vh'
        }
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Box paddingX={2} paddingTop={1} paddingBottom={3}>
        <DefaultTextField
          label="Pesquisar..."
          value={filterListValue}
          onChange={(e) => setFilterListValue(e.target.value)}
          required={false}
          margin="none"
        />
      </Box>

      {consolidatedRegionsListFiltered.map((region, index) => {
        return (
          <Typography
            key={index}
            className="advisorlist-menuitem"
            onClick={(_) => handleChangeAdvisorOrConsolidatedRegion(region, undefined)}
            variant="subtitle2"
          >
            {region.name}
          </Typography>
        );
      })}

      {consolidatedRegionsListFiltered.length > 0 && advisorListFiltered.length > 0 && (
        <Box marginY={1}>
          <Divider />
        </Box>
      )}

      {advisorListFiltered.map((advisor, index) => {
        return (
          <Typography
            key={index}
            className="advisorlist-menuitem"
            onClick={() => handleChangeAdvisorOrConsolidatedRegion({ id: null }, advisor)}
            variant="subtitle2"
          >
            {advisor.name}
          </Typography>
        );
      })}
    </Menu>
  );
};

export default AdvisorList;
