import Axios from 'axios';
import baseURL from '../variables/api';

const List = async (token, storeCode, category) => {
  return Axios.get(`${baseURL}/peb/client-extra-scores/${storeCode}/${category}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const Done = async (token, idExtraScore, storeCode, category, formData) => {
  return Axios.put(
    `${baseURL}/peb/client-extra-scores/${idExtraScore}/done/${storeCode}/${category}`,
    formData,
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

const AdvisorList = async (token) => {
  return Axios.get(`${baseURL}/client-extra-scores`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const AdvisorValidateExtraScore = async (token, extraScoreId) => {
  return await Axios.put(
    `${baseURL}/client-extra-scores/${extraScoreId}/validate`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

const AdvisorInvalidateExtraScore = async (token, extraScoreId, idReason) => {
  return await Axios.put(
    `${baseURL}/client-extra-scores/${extraScoreId}/invalidate/${idReason}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token
      }
    }
  );
};

const ExtraScoresService = {
  List,
  Done,
  AdvisorList,
  AdvisorValidateExtraScore,
  AdvisorInvalidateExtraScore
};

export default ExtraScoresService;
