import React from 'react';
import { Alert } from 'reactstrap';

export default function ErrorAlert ({ text }) {
  return (
    <Alert
      color="primary"
      style={{
        position: 'fixed',
        top: '30px',
        zIndex: 9999
      }}
    >
      {text}
    </Alert>
  );
}
