import EmblemPDV from '../assets/img/emblems/pdv.jpg';
import EmblemGoDigital from '../assets/img/emblems/godigital.jpg';
import EmblemAtendimento from '../assets/img/emblems/atendimento.jpg';
import EmblemPesquisa from '../assets/img/emblems/pesquisa_satisfacao.jpg';
import EmblemNPS from '../assets/img/emblems/nps.jpg';
import EmblemPontuacaoExtra from '../assets/img/emblems/pontuacoes_extras.jpg';
import EmblemTreinamento from '../assets/img/emblems/treinamento.jpg';
import EmblemSellOut from '../assets/img/emblems/leitura_sell_out.jpg';
import EmblemPerfilLoja from '../assets/img/emblems/perfil_loja.jpg';
import EmblemQuestionarioBandag from '../assets/img/emblems/questionario_bandag.jpg';
import EmblemInativo from '../assets/img/emblems/inativo.jpg';

export const EmblemImages = {
  pdv: EmblemPDV,
  godigital: EmblemGoDigital,
  atendimento: EmblemAtendimento,
  pesquisa_satisfacao: EmblemPesquisa,
  nps: EmblemNPS,
  pontuacoes_extras: EmblemPontuacaoExtra,
  treinamento: EmblemTreinamento,
  leitura_sell_out: EmblemSellOut,
  perfil_loja: EmblemPerfilLoja,
  questionario_bandag: EmblemQuestionarioBandag,
  inativo: EmblemInativo
};
