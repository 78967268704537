import React, { useState } from 'react';

const useForceRender = () => {
  const [_, setForceRender] = useState(0);

  return () => {
    setForceRender(Math.random());
  };
};

export default useForceRender;
