import './nps.scss';

import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Loader from '../Bridgestone/Loader';
import { Alert } from 'reactstrap';
import NpsService from '../../services/NpsService';
import { UserContext } from '../Contexts/UserContext';
import { StoreContext } from '../Contexts/StoreContext';
import FileSaver from 'file-saver';
import { HelpOutline } from '@mui/icons-material';
import DialogDownloadNPS from '../Dialog/DialogDownloadNPS';

const defaultNps = {
  average: 0,
  totalResults: 0,
  yourResults: []
};

const Nps = ({
  containerProps = {
    className: 'shadow',
    marginTop: 4
  },
  showHeader = true,
  showNpsDownloadClientButton = false,
  showNpsDownloadAllButton = false,
  compare
}) => {
  const { token } = useContext(UserContext);
  const { storeCode, storeName, selectedCategory, consolidatedRegion } = useContext(StoreContext);

  const [nps, setNps] = useState(defaultNps);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [modalDownloadSurveys, setModalDownloadSurveys] = useState(false);

  const infoTooltip = (
    <>
      <Typography
        variant="subtitle2"
        className="background-primary-2 color-white infonps-title"
        padding={1}
      >
        COMO FUNCIONA?
      </Typography>

      <Typography
        variant="subtitle2"
        className="color-white fontsize-12"
        paddingX={2.5}
        paddingY={1.5}
      >
        <span className="fontweight-bold"> Significado de NPS: </span>

        <Typography className="fontsize-12">
          Net Promoter Score - Indicador de desempenho que avalia a revenda tendo como base o ponto
          de vista e a experiência do cliente em toda sua jornada.
        </Typography>
      </Typography>

      <Typography
        variant="subtitle2"
        className="color-white fontsize-12"
        paddingX={2.5}
        paddingY={1.5}
      >
        <span className="fontweight-bold"> Fórmula: </span>

        <Typography className="fontsize-12"> NPS = % Promotores - % Detratores </Typography>
      </Typography>

      <Typography
        variant="subtitle2"
        className="color-white fontsize-12"
        paddingX={2.5}
        paddingY={1.5}
      >
        <span className="fontweight-bold"> O que é considerado bom? </span>

        <Typography className="fontsize-12"> - Excelente: 70 a 100 </Typography>
        <Typography className="fontsize-12"> - Ótimo: 30 a 69 </Typography>
        <Typography className="fontsize-12"> - Bom: 1 a 29 </Typography>
        <Typography className="fontsize-12"> - Precisa melhorar: -100 a 0 </Typography>
      </Typography>
    </>
  );

  useEffect(() => {
    if (!selectedCategory) {
      return;
    }

    setLoading(true);

    NpsService.Average(
      token,
      storeCode,
      selectedCategory,
      consolidatedRegion ? consolidatedRegion.id : null,
      compare
    )
      .then((resp) => {
        setNps(resp.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [storeCode, selectedCategory, compare]);

  useEffect(() => {
    if (!selectedCategory || !consolidatedRegion || !consolidatedRegion.id) {
      return;
    }

    setLoading(true);

    NpsService.Average(
      token,
      storeCode,
      selectedCategory,
      consolidatedRegion ? consolidatedRegion.id : null,
      compare
    )
      .then((resp) => {
        setNps(resp.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [consolidatedRegion]);

  const handleError = (message, color = 'danger') => {
    setError({ message, color });

    setTimeout(() => {
      setError(undefined);
    }, 4000);
  };

  return (
    <>
      {loading && <Loader />}

      {error && (
        <Alert color={error.color} style={{ position: 'fixed', top: '30px', zIndex: 9999 }}>
          {error.message}
        </Alert>
      )}

      <Grid
        container
        {...containerProps}
        className={`nps-container ${containerProps.className || ''}`}
      >
        {showHeader && (
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item lg={6} xs={12}>
                <h6 className="text-uppercase text-light ls-1 mb-1"> Avaliação do Cliente </h6>
                <h2 className="mb-0">Índice de recomendação da revenda (NPS)</h2>
              </Grid>

              <Grid item lg={6} xs={12} textAlign="right">
                <Tooltip
                  title={infoTooltip}
                  arrow
                  componentsProps={{
                    tooltip: {
                      className: 'infonps-tooltip background-primary'
                    },
                    arrow: {
                      className: 'color-primary-2'
                    }
                  }}
                >
                  <IconButton
                    className="iconbutton-info background-red color-white"
                    size="small"
                  >
                    <HelpOutline fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} className="nps-details" marginTop={2}>
          <Grid container>
            <Grid item xs={3} className="nps-result textalign-center">
              <Box marginY={1}>
                <Typography variant="h4" className="fontweight-600">
                  {nps.average}
                </Typography>
              </Box>

              <Typography variant="body2" component="h6" className="fontweight-bold">
                seu NPS
              </Typography>

              <Typography variant="caption" component="h6">
                {nps.totalResults} questões respondidas
              </Typography>
            </Grid>

            {nps.yourResults.map((result, index) => {
              const textColor =
                index == 0 ? 'color-red' : index == 1 ? 'color-yellow' : 'color-green';

              return (
                <Grid item xs={3} className={`nps-result textalign-center`} key={index}>
                  <Box marginY={1}>
                    <Typography variant="h4" className={`fontweight-600 ${textColor}`}>
                      {result.value}
                      <Typography
                        variant="span"
                        className={`fontsize-14 ${textColor}`}
                        marginLeft={1}
                      >
                        %
                      </Typography>
                    </Typography>
                  </Box>

                  <Typography
                    variant="body2"
                    component="h6"
                    className={`fontweight-bold ${textColor}`}
                  >
                    {result.title}
                  </Typography>

                  <Typography variant="caption" component="h6" className={`${textColor}`}>
                    {result.total} respostas
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        {showNpsDownloadClientButton && (
          <Grid item xs={12} marginTop={2} textAlign="right">
            <Button
              className="background-primary color-white borderradius-default fontsize-12"
              onClick={() => setModalDownloadSurveys(true)}
            >
              Baixar NPS detalhado
            </Button>
          </Grid>
        )}

        {showNpsDownloadAllButton && (
          <Grid item xs={12} marginTop={2} textAlign="right">
            <Button
              className="background-primary color-white borderradius-default fontsize-12"
              onClick={() => setModalDownloadSurveys(true)}
            >
              Baixar NPS detalhado
            </Button>
          </Grid>
        )}
      </Grid>

      <DialogDownloadNPS
        isOpen={modalDownloadSurveys}
        setClosedDialog={(_) => setModalDownloadSurveys(false)}
        token={token}
        setLoading={setLoading}
        setError={handleError}
        selectedCategory={selectedCategory}
        store={showNpsDownloadClientButton}
        storeCode={storeCode}
        storeName={storeName}
      />
    </>
  );
};

export default Nps;
