import Axios from 'axios';
import BaseUrl from '../variables/api';

const Get = async (token, storeCode, category) => {
  return Axios.get(`${BaseUrl}/peb/ranking`, {
    headers: {
      Authorization: 'Bearer ' + token
    },
    params: {
      storeCode,
      category
    }
  });
};

const Details = async (token, storeCode, category) => {
  return Axios.get(`${BaseUrl}/peb/ranking-details/${storeCode}/${category}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const DetailsBandag = async (token, storeCode) => {
  return Axios.get(`${BaseUrl}/peb/bandag-assessment-details/${storeCode}`, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const List = async (token, category, region) => {
  let params = {
    category
  };

  if (region != 0) {
    params.region = region;
  }

  return Axios.get(`${BaseUrl}/peb/ranking`, {
    headers: {
      Authorization: 'Bearer ' + token
    },
    params
  });
};

const DownloadRanking = async (token, category) => {
  return Axios.get(`${BaseUrl}/peb/ranking/${category}/download`, {
    headers: {
      Authorization: 'Bearer ' + token
    },
    responseType: 'blob'
  });
};

const RankingService = {
  Get,
  Details,
  DetailsBandag,
  List,
  DownloadRanking
};

export default RankingService;
