import './style.scss';
import React, { useContext, useState } from 'react';

import { UserContext } from '../Contexts/UserContext';
import { IconButton } from '@mui/material';
import { Notifications as NotificationIcon } from '@mui/icons-material';
import Notifications from './Notifications';

export default function NotificationsTrigger({ onHeader = false }) {
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pendingNotificationsCount, setPendingNotificationsCount] = useState(0);

  const getClassNames = () => {
    let classNames = '';

    if (onHeader) {
      classNames += 'displaying-header';
    }

    return classNames;
  };

  return (
    <>
      {user.profileId == 6 && (
        <>
          <IconButton
            onClick={(e) => setAnchorEl(e.currentTarget)}
            className={`iconbutton-notificationtrigger ${getClassNames()}`}
          >
            <NotificationIcon />
            <span className={`text-count ${pendingNotificationsCount > 0 ? 'actived' : ''}`}>
              {pendingNotificationsCount}
            </span>
          </IconButton>

          <Notifications
            anchorEl={anchorEl}
            handleClose={() => setAnchorEl(null)}
            setPendingNotificationsCount={setPendingNotificationsCount}
          />
        </>
      )}
    </>
  );
}
