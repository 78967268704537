import React from 'react';

export default function RankingPositionVariation({ before, now }) {
  return (
    <>
      {now < before && (
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
            <path fill="#2dce89" d="M12 3l12 18h-24z" />
          </svg>
          {` +${before - now}`}
        </span>
      )}
      {before < now && (
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
            <path fill="#f5365c" d="M12 21l-12-18h24z" />
          </svg>
          {` -${now - before}`}
        </span>
      )}
    </>
  );
}
