import React from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';

import useActionPlansChart from '../../../hooks/useActionPlansChart';

export default function ActionPlansChart({ details }) {
  const { douPlansByStatus, chartOptions } = useActionPlansChart({ details });

  return (
    <Card className="shadow borderradius-default">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h6 className="text-uppercase text-light ls-1 mb-1">
              {' '}
              {details.total || 0} itens encontrados{' '}
            </h6>
            <h2 className="mb-0">Plano de ação - Por status</h2>
          </div>
        </Row>
      </CardHeader>

      <CardBody className="pb-5">
        <div className="chart dashboard-canvas">
          <Doughnut data={douPlansByStatus()} height={376} options={chartOptions} />
        </div>
      </CardBody>
    </Card>
  );
}
