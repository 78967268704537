const imageExtensions = ['png', 'jpg', 'jpeg'];
const IsImage = (name) => {
  if (!name) {
    return false;
  }

  const extension = GetExtension(name);
  return imageExtensions.some((ext) => extension === ext);
};

const IsPdf = (name) => {
  if (!name) {
    return false;
  }

  const extension = GetExtension(name);
  return extension === 'pdf';
};

const GetExtension = (name) => {
  if (!name) {
    return '';
  }

  return name.split('.').pop().toLowerCase();
};

const FileHelper = {
  IsImage,
  IsPdf,
  GetExtension
};

export default FileHelper;
