import Axios from 'axios';
import baseURL from '../variables/api';

const Save = async (eventType, storeCode, storeCategory, pageDescription, pageUrl) => {
  const authToken = localStorage.getItem('banana');

  return await Axios.post(
    `${baseURL}/peb/events`,
    {
      eventType: eventType,
      code: storeCode,
      category: storeCategory,
      description: pageDescription,
      url: pageUrl
    },
    {
      headers: {
        Authorization: 'Bearer ' + authToken
      }
    }
  );
};

const EventsService = {
  Save
};

export default EventsService;
