import Axios from 'axios';
import BaseUrl from '../variables/api';

const Change = async (token, storeCode, category, formData) => {
  return Axios.put(`${BaseUrl}/peb/avatar/${storeCode}/${category}`, formData, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  });
};

const AvatarService = {
  Change
};

export default AvatarService;
